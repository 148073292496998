import React from "react";
import {
  List,
  Filter,
  useListRepository,
  useFilter,
} from "elentari";
import Grid from "@material-ui/core/Grid";
import { Field } from "react-final-form";
import usersRepository from "./usersRepository";
import { Email } from "../../Components";
import CompaniesAutoComplete from "../companies/CompaniesAutoComplete";

const UserFilter = () => {
  const filter = useFilter({});
  return (
    <Filter {...filter} labels={{ find: "Buscar", clear: "Limpar" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Email />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            fullWidth
            name="companyId"
            label="Empresa"
            component={CompaniesAutoComplete}
          />
        </Grid>
      </Grid>
    </Filter>
  );
};

const UsersList = ({ ...props }) => {
  const listOptions = {
    defaultOrder: "id",
    fields: {
      id: {
        label: "Id",
      },
      email: {
        label: "E-mail",
      },
      company: {
        label: "Empresa",
        format: (value) => value ? value.name : ''
      }
    },
  };

  const listHook = useListRepository({
    repository: usersRepository,
    defaultSort: "id",
    path: "users",
    forceRemove: true,
  });

  delete listHook.undoRemove;
  listHook.deleteFItem = listHook.removeItem;
  delete listHook.removeItem;

  return (
    <>
      <UserFilter />
      <List
        {...props}
        {...listHook}
        onClickView={null}
        hideDownloadIcon
        listOptions={listOptions}
      />
    </>
  );
};

export default UsersList;
