import { createBasicRepository as createRepository } from "elentari";
import { paginate } from "../../../lib/feathers";
import { restApi } from "../../../services/api";
import { query } from "../../../lib/feathers";

export const checklistItemRepository = createRepository({
  path: "checklist-items",
  restApi,
  queryTransform: {
    description: query.like("description"),
    ...paginate,
  },
});
