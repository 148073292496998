import React from "react";
import { autocompleteHelpers } from "elentari";
import systemsRepository from "./systemsRepository";
import PureAutocomplete from "../../Components/PureAutocomplete";
const { toOption } = autocompleteHelpers;

export const loadSystems = async inputValue => {
  const response = await systemsRepository.list({
    query: {
      name: inputValue,
      order: "name asc",
    },
    paginate: {
      limit: 10,
    },
  });
  if (response.ok) {
    return response.data.map(toOption("name"));
  }
  return [];
};

const SystemsAutocomplete = props => (
  <PureAutocomplete
    {...props}
    repository={systemsRepository}
    labelOption="name"
    loadOptions={loadSystems}
  />
);

export default SystemsAutocomplete;
