import React from "react";
import { useTabsNavigation, CrudTabs, CrudRoute } from "elentari";
import ChecklistForm from "./ChecklistForm";
import ChecklistItemList from "./checklistitems/ChecklistItemList";
import ChecklistItemForm from "./checklistitems/ChecklistItemForm";

const ChecklistTabs = () => {
  const tabsNavigation = useTabsNavigation({
    mainPath: "checklists",
    withPaper: false,
    tabs: [
      { value: "", label: "Checklist" },
      { value: "checklistItems", label: "Itens" },
    ],
  });

  return (
    <CrudTabs {...tabsNavigation}>
      <CrudRoute render={props => <ChecklistForm {...props} />} />
      <CrudRoute
        name="checklistItems"
        render={props => (
          <ChecklistItemList
            onClickNew={tabsNavigation.toNewChild}
            onClickEdit={tabsNavigation.toEditChild}
            {...props}
          />
        )}
      />
      <CrudRoute
        name="checklistItems"
        isForm
        render={props => <ChecklistItemForm {...props} />}
      />
    </CrudTabs>
  );
};
export default ChecklistTabs;
