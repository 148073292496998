import React from "react";
import {
  List,
  useListRepository,
  Filter,
  useFilter,
  TextField,
  Select
} from "elentari";
import Grid from "@material-ui/core/Grid";
import { Field } from "react-final-form";
import { checklistRepository } from "./checklistRepository";
import SystemsAutocomplete from "../systems/SystemsAutocomplete";
import CompaniesAutoComplete from "../companies/CompaniesAutoComplete";

const ChecklistFilter = () => {
  const filter = useFilter({});

  return (
    <Filter {...filter} labels={{ find: "Buscar", clear: "Limpar" }}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Field
            label="Sistema"
            name="systemId"
            fullWidth
            component={SystemsAutocomplete}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Field
            name="name"
            label="Nome"
            fullWidth
            component={TextField} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Field
            fullWidth
            hideEmpty
            component={Select}
            options={[
              { label: 'Ativo', value: true },
              { label: 'Inativo', value: false }
            ]}
            label="Status"
            name="active"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Field
            label="Empresa"
            name="companyId"
            fullWidth
            component={CompaniesAutoComplete}
          />
        </Grid>
      </Grid>
    </Filter>
  );
};

const ChecklistList = () => {
  const listOptions = {
    defaultOrder: "system_name",
    fields: {
      system: {
        label: "Sistema",
        format: (value) => value ? value.name : ''
      },
      name: {
        label: "Nome",
      },
      active: {
        label: "Status",
        format: (value) => value ? 'Ativo' : 'Inativo'
      },
      company: {
        label: "Empresa",
        format: (value) => value ? value.name : ''
      }
    },
  };

  const listHook = useListRepository({
    repository: checklistRepository,
    defaultSort: "name",
    path: "checklists"
  });


  return (
    <>
      <ChecklistFilter />
      <List
        {...listHook}
        hideDownloadIcon
        onClickView={null}
        removeItem={null}
        listOptions={listOptions}
      />
    </>
  );
};
export default ChecklistList;
