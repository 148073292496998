import React from "react";
import Grid from "@material-ui/core/Grid";
import { CrudForm, useEntityManager, TextField, Checkbox } from "elentari";
import { Field } from "react-final-form";
import { checklistRepository } from "./checklistRepository";
import * as yup from "yup";
import yupValidation from "../../lib/yupValidation";
import SystemsAutocomplete from "../systems/SystemsAutocomplete";
import CompaniesAutoComplete from "../companies/CompaniesAutoComplete";

const ChecklistForm = (props) => {
  const entityManager = useEntityManager({
    repository: checklistRepository,
    initialData: {
      createdAt: new Date().toJSON(),
      active: true
    },
  });

  const checklistSchema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required("Obrigatório"),

    emails: yup
      .string()
      .nullable()
      .trim(),

    expirationDays: yup
      .number()
      .nullable()
      .min(1, "Coloque uma quantidade de dias válida"),
  });
  const validate = yupValidation(checklistSchema);

  const renderContent = () => (
    <Grid container spacing={2}>
      <Grid item sm={6} xs={12}>
        <Field
          label="Nome"
          name="name"
          fullWidth
          component={TextField}
        />
      </Grid>
      <Grid item sm={3} xs={12}>
        <Field
          label="Sistema"
          name="systemId"
          fullWidth
          component={SystemsAutocomplete}
        />
      </Grid>
      <Grid item sm={3} xs={12}>
        <Field
          label="Ativo"
          name="active"
          type='checkbox'
          component={Checkbox}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Field
          label="E-mails"
          name="emails"
          fullWidth
          component={TextField}
        />
      </Grid>
      <Grid item sm={3} xs={12}>
        <Field
          label="Empresa"
          name="companyId"
          fullWidth
          component={CompaniesAutoComplete}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field
          label="Dias para notificação"
          name="expirationDays"
          type="number"
          fullWidth
          component={TextField}
        />
      </Grid>
    </Grid>
  );

  return (
    <CrudForm
      {...props}
      {...entityManager}
      validate={validate}
      withPaper
    >
      {renderContent}
    </CrudForm>
  );
};
export default ChecklistForm;
