import React from 'react'
import {RecoverPassword} from 'elentari'
import api from '../../services/api';

export const handleSubmit = recoverPassword => async ({username}) => {
  const response = await recoverPassword({
    action: 'resetSend',
    username,
  });
  if (response.ok) {
    return Promise.resolve({ok: true, message: 'Email enviado, verifique sua caixa de entrada'})
  }
  return {ok: false, message: 'Email inválido'}
}

export default function CustomRecoverPassword({history}) {

  return (
    <RecoverPassword
      history={history}
      usernameLabel='Email'
      backLabel='Voltar'
      submitLabel='Recuperar'
      onBackClick={() => history.goBack()}
      onSubmit={handleSubmit(api.resetPassword)}
    />
  )
}


