import React from "react";
import {
  List,
  useListRepository,
  Filter,
  useFilter,
  TextField
} from "elentari";
import Grid from "@material-ui/core/Grid";
import { Field } from "react-final-form";
import { checklistItemRepository } from "./checklistItemRepository";

const ChecklistItemFilter = () => {
  const filter = useFilter({});

  return (
    <Filter {...filter} labels={{ find: "Buscar", clear: "Limpar" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field name="description" label="Descrição" fullWidth component={TextField} />
        </Grid>
      </Grid>
    </Filter>
  );
};

const ChecklistItemList = props => {
  const listOptions = {
    defaultOrder: "order_index",
    fields: {
      orderIndex: {
        label: "Ordem",
      },
      description: {
        label: "Descrição",
      }
    },
  };

  const listHook = useListRepository({
    repository: checklistItemRepository,
    path: "checklistitems",
    defaultSort: "order_index",
    query: [["checklistId", props.match.params.id]],
  });

  console.log(props)
  listHook.onClickEdit = props.onClickEdit;
  listHook.onClickNew = props.onClickNew;
  listHook.onClickView = undefined;

  return (
    <>
      <ChecklistItemFilter />
      <List
        {...listHook}
        hideDownloadIcon
        listOptions={listOptions}
      />
    </>
  );
};
export default ChecklistItemList;
