import { createBasicRepository as createRepository } from "elentari";
import { paginate } from "../../lib/feathers";
import { restApi } from "../../services/api";
import { query } from "../../lib/feathers";

export const checklistRepository = createRepository({
  path: "checklists",
  restApi,
  queryTransform: {
    name: query.like("name"),
    ...paginate,
  },
});
