import React from "react";
import Grid from "@material-ui/core/Grid";
import { Field } from "react-final-form";
import { CrudForm, TextField, useEntityManager } from "elentari";
import usersRepository from "./usersRepository";
import { Email } from "../../Components";
import * as yup from "yup";
import yupValidation from "../../lib/yupValidation";
import CompaniesAutoComplete from "../companies/CompaniesAutoComplete";

const userSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email Inválido")
    .required("Obrigatório"),
  password: yup
    .string()
    .notRequired()
    .when("isNew", (isNew, passwordSchema) =>
      isNew ? passwordSchema.required("Obrigatório") : passwordSchema
    ),
});

const validate = yupValidation(userSchema);

const UserForm = props => {
  const entityManager = useEntityManager({
    repository: usersRepository,
  });
  entityManager.item.isNew = entityManager.isNew;
  const isNew = entityManager.isNew;

  return (
    <>
      <CrudForm
        {...props}
        {...entityManager}
        validate={validate}
        withPaper
      >
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Email />
          </Grid>
          {isNew && (
            <Grid item sm={6} xs={12}>
              <Field
                fullWidth
                name="password"
                label="Senha"
                type="password"
                component={TextField}
              />
            </Grid>
          )}
          <Grid item sm={6} xs={12}>
            <Field
              fullWidth
              name="companyId"
              label="Empresa"
              component={CompaniesAutoComplete}
            />
          </Grid>
        </Grid>
      </CrudForm>
    </>
  );
};

export default UserForm;
