import React from "react";
import Grid from "@material-ui/core/Grid";
import { CrudForm, useEntityManager, TextField, Checkbox } from "elentari";
import { Field } from "react-final-form";
import * as yup from "yup";
import yupValidation from "../../../lib/yupValidation";
import { checklistItemRepository } from "./checklistItemRepository";

const ChecklistItemForm = props => {
  const entityManager = useEntityManager({
    repository: checklistItemRepository,
    initialData: {
      createdAt: new Date().toJSON(),
      positiveEvidenceRequired: false,
      negativeEvidenceRequired: false,
      expirationDateRequired: false,
    }
  });

  const checklistSchema = yup.object().shape({
    description: yup
      .string()
      .trim()
      .required("Obrigatório"),
    orderIndex: yup
      .number()
      .typeError('Número Inválido')
      .min(0, 'Maior deve ser maior que 0')
      .required("Obrigatório"),
  });
  const validate = yupValidation(checklistSchema);

  const handleSubmit = data => {
    data.checklistId = props.match.params.id
    return entityManager.onSubmit(data);
  };

  const renderContent = ({ values }) => (
    <Grid container spacing={2}>
      <Grid item sm={10} xs={12}>
        <Field
          label="Descrição"
          name="description"
          fullWidth
          component={TextField}
        />
      </Grid>
      <Grid item sm={2} xs={12}>
        <Field
          label="Ordem"
          name="orderIndex"
          fullWidth
          parse={value => value.replace(/[^0-9]/g, "")}
          component={TextField}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Field
          label="Exige evidência positiva"
          name="positiveEvidenceRequired"
          type='checkbox'
          component={Checkbox}
        />
      </Grid>
      <Grid item sm={8} xs={12}>
        <Field
          label="Mensagem evidência positiva"
          name="positiveEvidenceMessage"
          fullWidth
          component={TextField}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Field
          label="Exige evidência negativa"
          name="negativeEvidenceRequired"
          type='checkbox'
          component={Checkbox}
        />
      </Grid>
      <Grid item sm={8} xs={12}>
        <Field
          label="Mensagem evidência negativa"
          name="negativeEvidenceMessage"
          fullWidth
          component={TextField}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Field
          label="Exige data de validade"
          name="expirationDateRequired"
          type='checkbox'
          component={Checkbox}
        />
      </Grid>
      <Grid item sm={8} xs={12}>
        <Field
          label="Mensagem data de validade"
          name="expirationDateMessage"
          fullWidth
          component={TextField}
        />
      </Grid>
    </Grid>
  );

  return (
    <CrudForm
      {...entityManager}
      {...props}
      onSubmit={handleSubmit}
      validate={validate}
      withPaper
    >
      {renderContent}
    </CrudForm>
  );
};
export default ChecklistItemForm;
