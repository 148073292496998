import config from '../config'
import { create } from 'apisauce'

export const restApi = create({
  baseURL: config.baseURL
})

const api = {
  setToken: token => restApi.setHeader('Authorization', `Bearer ${token}`),
  ping: idUser => restApi.get(`/users/${idUser}`),
  login: (credentials) => restApi.post('/authentication', {
    strategy: "local",
    ...credentials
  }),
  resetPassword: data => restApi.post('/account', data),
}

export default api