import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { loginRoutes, PrivateRoute, Auth as AuthProvider } from 'elentari'
import React, { useState } from 'react'
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './Theme'
import {
  Login,
  ResetPassword,
  RecoverPassword,
  VerifyLogin
} from './modules/login'
import { MuiPickersUtilsProvider } from "material-ui-pickers"
import MomentUtils from "@date-io/moment"
import api from './services/api'
import { Home } from './HomeNavigation'

const App = () => {
  const [user, setUser] = useState()

  const withUser = user => {
    setUser(user)
    api.setToken(user.token)
  }

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AuthProvider withUser={withUser} onLogout={api.logout}>
          <VerifyLogin />
          <Router>
            <Switch>
              <Route path={loginRoutes.login} component={Login} />
              <Route path={loginRoutes.recoverPassword} component={RecoverPassword} />
              <Route path='/init' component={ResetPassword} />
              <PrivateRoute path='/' render={() => <Home user={user} />} />
            </Switch>
          </Router>
        </AuthProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default App
