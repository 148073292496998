import React from 'react'
import {
  AppWrap,
} from 'elentari'
import {
  Switch,
  Redirect,
  Route,
} from 'react-router-dom'
import * as R from 'ramda'
import CheckIcon from '@material-ui/icons/Check';
import AccountBox from "@material-ui/icons/AccountBox";
import ChecklistTabs from './modules/checklists/ChecklistTabs'
import ChecklistList from './modules/checklists/ChecklistList'
import logo from './images/g10.png'
import { UserForm, UsersList } from './modules/users';

const menuItems = [
  {
    label: 'Checklist',
    icon: CheckIcon,
    pathname: '/checklists',
  },
  {
    name: "users",
    label: "Usuários",
    icon: AccountBox,
    pathname: "/users",
  },
]

const Logo = () => {
  return (
  <div style={{color: 'white', display: 'flex', alignItems: 'center'}}>
    <img width={40} height={30} src={logo} alt="G10" />
  </div>
  )
}


export const Home = ({ user }) => {
  const defaultRedirect = R.pathOr('/', [0, 'pathname'], menuItems)
  return (
    <AppWrap
      logo={<Logo />}
      userAvatarProps={{
        action: 'Sair',
        label: 'Olá,'
      }}
      pathReadableMap={{
        checklistItems: "Items",
      }}
      menuItems={menuItems}>
      <Switch>
        <Route path='/checklists' exact component={ChecklistList} />
        <Route path='/checklists/:id' component={ChecklistTabs} />
        <Route path='/users' exact component={UsersList} />
        <Route path='/users/:id' component={UserForm} />
        <Redirect to={defaultRedirect} />
      </Switch>
    </AppWrap>
  )
}
