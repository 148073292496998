import React from 'react'
import { withLogin } from "elentari";
import api from '../../services/api'
import * as R from 'ramda'
import { Grid, Typography } from '@material-ui/core';
import LoginForm from "./LoginForm";

const Login = withLogin(LoginForm);

export const handleLogin = ({ login, setToken = R.empty }) => async ({
  email,
  password,
}) => {
  if (!email || !password) return;
  const response = await login({
    email,
    password,
  });
  if (response.ok) {
    if (!response.data.user) {
      return { ok: false };
    }
    if (!response.data.accessToken) {
      return { ok: false, message: "Token não encontrado" };
    }
    setToken(response.data.accessToken);
    return {
      ok: true,
      token: response.data.accessToken,
      id: response.data.user.id,
      company_id: response.data.user.companyId,
      name: email,
      role: response.data.user.role,
    };
  }
  return { ok: false };
};

const CustomLogin = ({ history }) => {
  const logo = (
    <Grid container direction="row" justify="center" alignItems="center">
      <img alt='logo' style={{ height: 38, margin: 32 }} src='https://www.g10.com.br/assets/img/footer-logo.png'/>
      <Grid item xs={12}>
        <Typography align="center" variant="h4" gutterBottom>
          Gerador de
        </Typography>
        <Typography align="center" variant="h4" color="primary">
          Checklists
        </Typography>
      </Grid>
    </Grid>
  )
  return (
    <Login
      history={history}
      onSubmit={handleLogin(api)}
      requiredLabel='Obrigatório'
      usernameLabel='Usuário'
      submitLabel='Entrar'
      passwordLabel='Senha'
      recoverPasswordLabel='Recuperar Senha'
      logo={logo} />
  )
}

export default CustomLogin;
